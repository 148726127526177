const moduleName = 'promo';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-promo')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const promo = self;
		const items = promo.querySelectorAll('.js-promo-item');

		if (items.length > 1) {
			const autoplay = !!promo.dataset.autoplay ? {delay: promo.dataset.autoplay} : false;
			const pagination = document.createElement('div');

			pagination.classList.add('promo__pagination');
			promo.appendChild(pagination);

			const promoSlider = new Swiper(promo, {
				loop: true,
				autoplay: autoplay,
				simulateTouch: false,
				pagination: {
					el: pagination,
					clickable: true,
					bulletClass: 'promo__bullet',
					bulletActiveClass: '_active'
				}
			});
		}
	});

};