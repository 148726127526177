const moduleName = 'nav';

const gcOverlay = require('./gcOverlay');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-nav')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const nav = self;
		const submenus = nav.querySelectorAll('.js-nav-submenu');
		const navOpenButton = nav.querySelector('.js-nav-open-button');
		const navCloseButton = nav.querySelector('.js-nav-close-button');
		const overlay = new gcOverlay();
		let windowWidth = window.innerWidth;

		navOpenButton.addEventListener('click', showNav);
		navCloseButton.addEventListener('click', hideNav);

		document.addEventListener('click', (event) => {
			if (!event.target.closest('.js-nav') && nav.classList.contains('_open')) {
				hideNav();
			}
		});

		window.addEventListener('resize', () => {
			if (window.innerWidth > 1024 && windowWidth <= 1024 && nav.classList.contains('_open')) {
				hideNav();
			}

			windowWidth = window.innerWidth;
		});

		Array.from(submenus).forEach((submenu) => {
			const item = submenu.closest('.js-nav-item');
			const submenuToggle = item.querySelector('.js-nav-submenu-toggle');

			item.addEventListener('mouseenter', () => {
				if (window.innerWidth <= 1024) return;
				
				showSubmenu(submenu);
			});

			item.addEventListener('mouseleave', () => {
				if (window.innerWidth <= 1024) return;

				hideSubmenu(submenu);
			});


			submenuToggle.addEventListener('click', () => {
				if (item.classList.contains('_open')) {
					hideSubmenu(submenu);
				} else {
					const openSiblingsItem = item.parentNode.querySelector('.js-nav-item._open');

					if (openSiblingsItem) {
						const openSubmenu = openSiblingsItem.querySelector('.js-nav-submenu');

						hideSubmenu(openSubmenu);
					}

					showSubmenu(submenu);
				}
			});
		});
		


		function showNav() {
			overlay.show();
			nav.classList.add('_open');
		}

		function hideNav() {
			overlay.hide();
			nav.classList.remove('_open');
		}

		function showSubmenu(submenu) {
			const submenuHolder = submenu.closest('.js-nav-item');
			let newHeight;

			submenuHolder.classList.add('_open');

			if (window.innerWidth <= 1024) {
				newHeight = submenu.offsetHeight;
				submenu.style.height = 0;
			}

			setTimeout(() => {
				submenu.classList.add('_visible');

				if (window.innerWidth <= 1024) {
					submenu.style.height = `${newHeight}px`;
				}

				setTimeout(() => {
					submenu.style.height = '';
				}, 200);
			}, 0);
		}

		function hideSubmenu(submenu) {
			const submenuHolder = submenu.closest('.js-nav-item');

			if (window.innerWidth <= 1024) {
				submenu.style.height = `${submenu.offsetHeight}px`;

				setTimeout(() => {
					submenu.style.height = 0;
				}, 0);

				setTimeout(() => {
					submenu.classList.remove('_visible');
					submenuHolder.classList.remove('_open');
					submenu.style.height = '';
				}, 200);
			} else {
				submenu.classList.remove('_visible');
				submenuHolder.classList.remove('_open');
			}
		}
	});

};