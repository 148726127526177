const moduleName = 'telMask';

const IMask = require('imask');

module.exports = () => {

	Array.from(document.querySelectorAll('input[type=tel]')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		new IMask(self, {
			mask: '+{7} (000) 000-00-00'
		});
	});

};