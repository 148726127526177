const moduleName = 'works';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-works')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const works = self;
		const items = works.querySelectorAll('.js-works-item');
		const prevBtn = works.querySelector('.js-works-button-prev');
		const nextBtn = works.querySelector('.js-works-button-next');

		const worksSlider = new Swiper(works, {
			slidesPerView: 'auto',
			simulateTouch: false,
			navigation: {
				prevEl: prevBtn,
				nextEl: nextBtn
			}
		});
	});

};