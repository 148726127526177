// polyfills
require('core-js/fn/array/from');
require('./modules/polyfills')();

// detect mobile devices
require('./modules/mobileDetect')();

// external svg support for old browsers
require('svgxuse');

// global variables
const $ = jQuery = window.$ = window.jQuery = require('jquery');
const initModules = window.initModules = () => {
	require('./modules/nav')();
	require('./modules/fancybox')();
	require('./modules/promo')();
	require('./modules/services')();
	require('./modules/works')();
	require('./modules/popup')();
	require('./modules/gallery')();
	require('./modules/telMask')();
	require('./modules/floatHeader')();
};

// init all modules
if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
	initModules();
} else {
	document.addEventListener('DOMContentLoaded', initModules);
}