const moduleName = 'gallery';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-gallery')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const gallery = self;
		
		gallery.insertAdjacentHTML('beforeend', `
			<button class="gallery__prev arrow">
			<svg class="arrow__icon">
			<use xlink:href="images/symbols.svg#arrow-left"></use>
			</svg>
			</button>
			<button class="gallery__next arrow">
			<svg class="arrow__icon">
			<use xlink:href="images/symbols.svg#arrow-right"></use>
			</svg>
			</button>`);

		const prevBtn = gallery.querySelector('.gallery__prev');
		const nextBtn = gallery.querySelector('.gallery__next');

		const gallerySlider = new Swiper(gallery, {
			slidesPerView: 'auto',
			simulateTouch: false,
			navigation: {
				prevEl: prevBtn,
				nextEl: nextBtn
			}
		});
	});

};