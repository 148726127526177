const gcOverlay = require('./gcOverlay');

module.exports = (element) => {

	const popupId = typeof element === 'string' ? element : `#${element.id}`;
	const popup = document.querySelector(popupId);
	const popupCloseButtons = popup.querySelectorAll('.js-popup-close-button');
	const popupOpenButtons = document.querySelectorAll(`[data-popup="${popupId}"].js-popup-open-button,
														[href="${popupId}"].js-popup-open-button`);
	const overlay = new gcOverlay();

	popup.parentNode.removeChild(popup);
	document.body.appendChild(popup);

	Array.from(popupOpenButtons).forEach((popupOpenButton) => {
		popupOpenButton.addEventListener('click', openPopup);
	});

	Array.from(popupCloseButtons).forEach((popupCloseButton) => {
		popupCloseButton.addEventListener('click', closePopup);
	});

	popup.addEventListener('click', (event) => {
		if (!event.target.closest('.js-popup-body')) {
			closePopup();
		}
	});

	window.addEventListener('keydown', (event) => {
		if (event.keyCode === 27 && popup.classList.contains('_active')) {
			closePopup();
		}
	});



	function openPopup(event) {
		closePopup();

		popup.classList.add('_active');

		setTimeout(() => {
			popup.classList.add('_visible');
		}, 0);

		overlay.show();

		event.preventDefault();
	}

	function closePopup() {
		const activePopup = document.querySelector('.js-popup._active');

		if (!activePopup) return;

		activePopup.classList.remove('_visible');

		setTimeout(() => {
			activePopup.classList.remove('_active');
		}, 200);

		overlay.hide();
	}



	return {
		open: openPopup,
		close: closePopup
	};

};