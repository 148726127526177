const moduleName = 'floatHeader';

module.exports = () => {

	Array.from(document.querySelectorAll('.js-float-header')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const header = self;

		fixHeader();

		window.addEventListener('scroll', fixHeader);
		window.addEventListener('resize', fixHeader);



		function fixHeader() {
			const scrollY = window.scrollY || document.documentElement.scrollTop;

			if (header.offsetHeight + header.getBoundingClientRect().top < 40) {
				header.classList.add('_fixed');
			} else {
				header.classList.remove('_fixed');
			}
		}
	});
	
};