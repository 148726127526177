const moduleName = 'services';

const Swiper = require('swiper');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-services')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const services = self;
		const items = services.querySelectorAll('.js-services-item');

		if (items.length > 1) {
			const pagination = document.createElement('div');
			const autoplay = !!services.dataset.autoplay ? {delay: services.dataset.autoplay} : false;

			pagination.classList.add('services__pagination');
			services.appendChild(pagination);

			const servicesSlider = new Swiper(services, {
				loop: true,
				slidesPerView: 'auto',
				autoHeight: true,
				simulateTouch: false,
				autoplay: autoplay,
				pagination: {
					el: pagination,
					clickable: true,
					bulletClass: 'services__bullet',
					bulletActiveClass: '_active'
				},
				breakpoints: {
					567: {
						slidesPerGroup: 1
					},
					1023: {
						slidesPerGroup: 2
					}
				}
			});
		}
	});

};