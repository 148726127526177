const moduleName = 'popup';

const gcPopup = require('./gcPopup');

module.exports = () => {

	Array.from(document.querySelectorAll('.js-popup')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const popup = new gcPopup(self);
	});

};